'use client';
import styled from '@emotion/styled';

import Header from '../_common/Header';
import Footer from '../_common/Footer';
import BottomNavi from '../_common/BottomNavi';
import { useEffect } from 'react';

export default function Layout({
    children,
}: Readonly<{
    children: React.ReactNode;
}>) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <_inner>
            <Header />
            <_main>{children}</_main>
            <Footer />
            <BottomNavi />
        </_inner>
    );
}

const _inner = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;
const _main = styled.main`
    flex: 1;
    display: flex;
`;
